import ToggleButton from '@material-ui/lab/ToggleButton'

import styled from 'styled-components'

export const StyledToggleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`
export const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ApprovalsContainer = styled.div`
  margin: 20px 50px;
`

export const StyledToggleButton = styled(ToggleButton)`
  color: ${({ theme }) => theme.colors.gray[100]};
  border-color: ${({ theme }) => theme.colors.nightshade.dark};
  height: 28px;
  width: 160px;
  text-transform: none;
  font-size: 1rem;
  padding: 4px 15px;
  cursor: pointer;
  margin: auto;
  display: flex;
  align-items: center;
  border-radius: 1rem;

  background-color: ${({ theme }) => theme.colors.nightshade.light};

  &.Mui-selected {
    background-color: ${({ theme }) => theme.colors.nightshade.dark};
    color: ${({ theme }) => theme.colors.gray[100]};
    border-color: ${({ theme }) => theme.colors.nightshade.medium};

    &:hover {
      background-color: ${({ theme }) => theme.colors.nightshade.dark};
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.nightshade.dark};
    border-color: ${({ theme }) => theme.colors.nightshade.medium};
  }
`
