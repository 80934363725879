import styled, { css } from 'styled-components'
import Button from '@material-ui/core/Button'
import { Accordion, TextareaAutosize } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'

export const StyledApproveButton = styled(Button)(
  ({ theme: { colors } }) => css`
    background-color: ${colors.kellyGreen.medium};
    color: white;
    padding: 0.2rem;
    border-radius: 10px;

    &:hover {
      background-color: ${colors.kellyGreen.dark};
    }
  `,
)

export const StyledRejectButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.gray[300]};
  color: white;
  padding: 0.2rem;
  border-radius: 10px;
  position: relative;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[500]};
  }
`

export const StyledButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const StyledRejectContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  width: 200px;
`

export const StyledAccordion = styled(Accordion)`
  border-top: none;
  border-left: none;
  border-right: none;
  width: 80%;
  max-width: 1000px;
`

export const ApprovalsViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
`

export const StyledRejectReason = styled(TextareaAutosize)({
  marginTop: '10px',
  maxWidth: '600px',
  maxHeight: '600px',
})
