import React from 'react'
import { Button } from '@material-ui/core'

const GeneralButton = ({ children, extraStyles, onClick, otherProps }) => {
  const defaultStyles = {
    fontSize: '0.8125rem',
    width: 'fit-content',
    padding: '5px 15px',
    backgroundColor: 'rgb(88, 86, 255)',
    color: '#ffffff',
    height: 'fit-content'
  }
  return (
    <Button style={{ ...defaultStyles, ...extraStyles }} onClick={onClick} {...otherProps}>
      {children}
    </Button>
  )
}

export default GeneralButton
