import TableCell from '@material-ui/core/TableCell'
import React from 'react'
import ActionButtons from './ActionButtons'

function getWeekStart(date) {
  const d = new Date(date)
  const day = d.getDay()
  const diff = d.getDate() - day + (day === 0 ? -6 : 1)
  const weekStart = new Date(d.setDate(diff))
  weekStart.setHours(0, 0, 0, 0)
  return weekStart
}

export function groupTimeEntriesByWeek(entries) {
  const weeklyData = {}

  entries.forEach(entry => {
    const startDate = new Date(entry.start)
    const endDate = new Date(entry.end)
    const fallbackDuration = (endDate - startDate) / (1000 * 60 * 60)
    const durationHours = entry.durationInHours ?? fallbackDuration

    const weekStart = getWeekStart(startDate)
    const personKey = `${entry.personId}-${weekStart}`

    if (!weeklyData[personKey]) {
      weeklyData[personKey] = {
        id: entry._id,
        personId: entry.personId, // comment: '',
        personName: '',
        week: weekStart.toISOString().split('T')[0],
        weekRaw: weekStart,
        hours: 0,
        billable: true,
        entries: [],
      }
    }

    weeklyData[personKey].hours = +(weeklyData[personKey].hours + durationHours).toFixed(2)
    weeklyData[personKey].entries.push(entry)
  })

  return Object.values(weeklyData)
}

export const generateRows = (onApprove, onConfirmReject) => [
  {
    rowKey: 'name',
    render: row => <TableCell>{row.personName}</TableCell>,
  },
  {
    rowKey: 'hours',
    render: row => <TableCell>{row.hours}</TableCell>,
  },
  {
    rowKey: 'week',
    render: row => <TableCell>{row.week}</TableCell>,
  },
  {
    rowKey: 'actions',
    render: timeEntry => {
      return (
        <TableCell>
          <ActionButtons
            onApprove={() => onApprove(timeEntry)}
            onConfirmReject={rejectReason => onConfirmReject(timeEntry, rejectReason)}
          />
        </TableCell>
      )
    },
  },
]

// TIME
const getMondayOfCurrentWeek = (date = new Date()) => {
  const day = date.getDay()
  const diff = (day === 0 ? -6 : 1) - day
  return new Date(date.setDate(date.getDate() + diff))
}

const resetTimeToStartOfDay = date => {
  date.setHours(0, 0, 0, 0)
  return date
}

const resetTimeToEndOfDay = date => {
  date.setHours(23, 59, 59, 999)
  return date
}

export const getLastWeekAndCurrentRanges = () => {
  const today = new Date()
  const thisMonday = resetTimeToStartOfDay(getMondayOfCurrentWeek(new Date(today)))
  const thisSunday = resetTimeToEndOfDay(new Date(thisMonday))
  thisSunday.setDate(thisMonday.getDate() + 6)
  const lastMonday = resetTimeToStartOfDay(new Date(thisMonday))
  lastMonday.setDate(thisMonday.getDate() - 7)
  const lastSunday = resetTimeToEndOfDay(new Date(lastMonday))
  lastSunday.setDate(lastMonday.getDate() + 6)

  return {
    lastWeek: { start: lastMonday, end: lastSunday },
    thisWeek: { start: thisMonday, end: thisSunday },
  }
}
