import { useCallback, useEffect, useMemo, useState } from 'react'
import Project from '../../server/models/project'
import { getTimeEntriesByProject } from '../../entities/timeEntry/service'
import { generateRows, groupTimeEntriesByWeek } from './Approvals.utils'
import * as peopleService from '../../entities/people/service'
import * as timeEntryService from '../../entities/timeEntry/service'
import Assignment from '../../server/models/assignment'
import { getAllTimesheets } from '../../entities/timesheet/service'

const useApprovalsData = ({ client, person }) => {
  const [timeEntries, setTimeEntries] = useState({
    approved: [],
    pending: [],
  })
  const [people, setPeople] = useState([])
  const [projects, setProjects] = useState([])
  const [timesheets, setTimesheets] = useState([])

  useEffect(() => {
    const getLeadingProjects = async () => {
      const userAssignments = await Assignment.toArray()

      const leadingAssignments = userAssignments.filter(assignment => assignment.type !== 0)

      const allProjects = await Project.toArray()
      const leadingProjects = allProjects.filter(project =>
        leadingAssignments.some(assignment => assignment.projectId === project._id),
      )

      setProjects(leadingProjects)
    }

    getLeadingProjects()
  }, [])

  useEffect(() => {
    if (!projects.length) return

    const mapPeopleData = async (ids, timeEntriesData) => {
      const assignedPeople = await peopleService.getAllPeople(client, { peopleIds: ids })
      const peopleHash = assignedPeople.reduce((acc, personData) => {
        acc[personData._id] = personData
        return acc
      }, {})

      setPeople(peopleHash)
      const approved = timeEntriesData.approved.map(entry => {
        const personData = peopleHash[entry.personId]
        return { ...entry, personName: personData.fullName }
      })
      const pending = timeEntriesData.pending.map(entry => {
        const personData = peopleHash[entry.personId]
        return { ...entry, personName: personData.fullName }
      })

      return { approved, pending }
    }

    const getTimeEntries = async () => {
      const pendingTimeEntries = await getTimeEntriesByProject({
        projectId: projects.map(project => project._id).join(','),
        status: ['pending'],
        client,
      })
      const fifteenDaysAgo = new Date() - 15 * 24 * 60 * 60 * 1000

      const approvedTimeEntries = await getTimeEntriesByProject({
        projectId: projects.map(project => project._id).join(','),
        status: ['approved'],
        client,
        startDate: fifteenDaysAgo,
        endDate: new Date(),
      })

      const timesheetsIdsSet = new Set(
        [...pendingTimeEntries, ...approvedTimeEntries].map(entry => entry.repliconTimesheetId),
      )

      const timesheetsData = await getAllTimesheets({
        variables: {
          onlyApproved: true,
          repliconIds: Array.from(timesheetsIdsSet),
        },

        client,
      })
      const submittedTimeEntries = pendingTimeEntries.filter(entry =>
        timesheetsData.some(timesheet => {
          const isApproved = timesheet.approvalStatus === 'Approved'
          const isSameTimesheet = timesheet.repliconId === entry.repliconTimesheetId
          return isApproved && isSameTimesheet
        }),
      )

      const pendingTimeEntriesData = groupTimeEntriesByWeek(submittedTimeEntries)
      const approvedTimeEntriesData = groupTimeEntriesByWeek(approvedTimeEntries)

      const idSet = new Set(
        [...pendingTimeEntriesData, ...approvedTimeEntriesData].map(entry => entry.personId),
      )

      const peopleIds = [...idSet]
      const { approved, pending } = await mapPeopleData(peopleIds, {
        approved: approvedTimeEntriesData,
        pending: pendingTimeEntriesData,
      })
      setTimeEntries({ approved, pending })
      setTimesheets(timesheetsData)
    }
    getTimeEntries()
  }, [projects])

  const updateTimeEntry = useCallback(
    async (entry, rejectionReason, status) => {
      const promises = entry.entries.map(timeEntry => {
        return timeEntryService.updateTimeEntry({
          client,
          timeEntry: {
            ...timeEntry,
            approveState: { rejectionReason, status, modifiedBy: person._id },
          },
        })
      })

      const results = await Promise.all(promises)
      if (results) {
        setTimeEntries(oldTimeEntries => {
          const pendingEntries = oldTimeEntries.pending.filter(
            row => !(row.personName === entry.personName && row.week === entry.week),
          )

          if (status === 'approved') {
            return {
              pending: pendingEntries,
              approved: [...oldTimeEntries.approved, ...entry.entries],
            }
          }

          if (status === 'rejected') {
            return {
              pending: pendingEntries,
              approved: oldTimeEntries.approved,
            }
          }

          return {
            pending: [...pendingEntries, ...entry.entries],
            approved: oldTimeEntries.approved,
          }
        })
      }
    },
    [timeEntries],
  )

  const approvalsTableRows = useMemo(
    () =>
      generateRows(
        entry => updateTimeEntry(entry, null, 'approved'),
        (entry, rejectReason) => updateTimeEntry(entry, rejectReason, 'rejected'),
      ),
    [updateTimeEntry],
  )

  return { projects, timeEntries, approvalsTableRows, people, timesheets }
}

export default useApprovalsData
