import React from 'react'
import TableCell from '@material-ui/core/TableCell'

import PropTypes from 'prop-types'
import { AccordionSummary } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'

import { ApprovalsViewContainer, StyledAccordion } from './ApprovalView.styles'
import { ProjectsTableContainer, StyledTableHead } from './ProjectsView.styles'

document.body.style.overflow = 'auto'

const ApprovalTable = ({ projects, timeEntries, rows }) => {
  return (
    <ApprovalsViewContainer>
      <div>{/* <DropdownWithCards /> */}</div>
      {projects.map(project => (
        <StyledAccordion key={project.name}>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <Typography>{project.name}</Typography>
          </AccordionSummary>
          <TableContainer component={ProjectsTableContainer} rows={5}>
            <Table aria-label="simple table">
              <StyledTableHead>
                <TableRow>
                  <TableCell>Person Name</TableCell>
                  <TableCell>Hours</TableCell>
                  <TableCell>Week</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {timeEntries.map(timeEntry => (
                  <TableRow key={`${timeEntry.name}-${timeEntry.week}`}>
                    {rows.map(row => row.render(timeEntry))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledAccordion>
      ))}
    </ApprovalsViewContainer>
  )
}

ApprovalTable.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object),
  timeEntries: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
}

export default ApprovalTable
