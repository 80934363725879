import React, { useState } from 'react'

import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core'
import {
  StyledApproveButton,
  StyledButtonContainer,
  StyledRejectButton,
  StyledRejectReason,
} from './ApprovalView.styles'

const ActionButtons = ({ onApprove, onConfirmReject }) => {
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false)

  const handleRejectDialogClose = () => {
    setIsRejectDialogOpen(false)
  }

  return (
    <StyledButtonContainer>
      <StyledApproveButton onClick={onApprove}>Approve</StyledApproveButton>
      <StyledRejectButton onClick={() => setIsRejectDialogOpen(true)}>Reject</StyledRejectButton>

      <RejectDialog
        open={isRejectDialogOpen}
        onCancel={handleRejectDialogClose}
        onAccept={reason => {
          onConfirmReject(reason)
          handleRejectDialogClose()
        }}
      />
    </StyledButtonContainer>
  )
}

const RejectDialog = ({ open, onAccept, onCancel }) => {
  const [reason, setReason] = useState('')

  const handleAccept = () => {
    onAccept(reason)
    setReason('')
  }

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Reject Reason</DialogTitle>
      <DialogContent>
        <StyledRejectReason
          minRows={4}
          maxRows={10}
          label="Reason"
          variant="outlined"
          fullWidth
          value={reason}
          onChange={e => setReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <StyledRejectButton onClick={onCancel}>Cancel</StyledRejectButton>
        <StyledApproveButton onClick={handleAccept}>Confirm</StyledApproveButton>
      </DialogActions>
    </Dialog>
  )
}

export default ActionButtons
