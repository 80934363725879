import React, { PureComponent } from 'react'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Views } from 'react-big-calendar'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'
import ListOutlinedIcon from '@material-ui/icons/ListOutlined'
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined'

import Popover from '@material-ui/core/Popover'

import timeUtils from '../../../../utils/timeUtils'
import DayPickerCalendar from '../DayPickerCalendar'
import CustomTooltip from '../../../../components/CustomTooltip'

import { ArrowLeft, ArrowRight } from '../styles'
import {
  DatePickerWrapper,
  PeriodSwitcherContainer,
  ViewSwitcherContainer,
  styles,
  DateRange,
  CustomToggle,
  DatePickerContainer,
} from './style'
import { shouldHideArrows } from '../../utils'
import { TIMESHEET_VIEWS } from '../../../../constants/glimpseViews'
import { userSettingsPropTypes } from '../../../../constants/userSettings'
import { Container } from '@material-ui/core'

class CalendarDateSelector extends PureComponent {
  datePickerRef = React.createRef()

  handleChange = (event, dayViewPeriod) => {
    if (!dayViewPeriod) return null
    if (dayViewPeriod === Views.DAY) return this.props.setViewPeriodDay()
    return this.props.setViewPeriodWeek()
  }

  handleNavigateLeftClick = () => {
    this.props.onNavigate(-1)
  }

  handleNavigateRightClick = () => {
    this.props.onNavigate(1)
  }

  renderPeriodSwitcher = () => {
    const { classes } = this.props
    const isWeekViewDisabled = this.props.view === TIMESHEET_VIEWS.LIST
    return (
      <PeriodSwitcherContainer style={{ marginRight: '10px' }}>
        <ToggleButtonGroup
          classes={{ root: classes.toggleButtonGroup }}
          value={this.props.isDayViewPeriodSelected ? Views.DAY : Views.WEEK}
          onChange={this.handleChange}
          exclusive
        >
          <CustomToggle data-testid={`dayPeriodTab.${Views.DAY}`} value={Views.DAY}>
            <span>Day</span>
          </CustomToggle>
          <CustomToggle
            data-testid={`dayPeriodTab.${Views.WEEK}`}
            value={Views.WEEK}
            disabled={isWeekViewDisabled}
          >
            <span>Week</span>
          </CustomToggle>
        </ToggleButtonGroup>
      </PeriodSwitcherContainer>
    )
  }

  renderViewSwitcher = () => {
    const { classes } = this.props
    const isWeekPeriodSelected = !this.props.isDayViewPeriodSelected

    return (
      <ViewSwitcherContainer style={{ marginLeft: '10px' }}>
        <ToggleButtonGroup
          classes={{ root: classes.toggleButtonGroup }}
          value={this.props.view}
          onChange={this.props.handleTogglePageView}
          exclusive
        >
          <CustomToggle
            data-testid={`viewTab.${TIMESHEET_VIEWS.TIMELINE}`}
            value={TIMESHEET_VIEWS.TIMELINE}
          >
            <CustomTooltip title="Timeline View">
              <CalendarTodayOutlinedIcon style={{ fontSize: '14px' }} />
            </CustomTooltip>
          </CustomToggle>
          <CustomToggle
            data-testid={`viewTab.${TIMESHEET_VIEWS.LIST}`}
            value={TIMESHEET_VIEWS.LIST}
            disabled={isWeekPeriodSelected}
          >
            <CustomTooltip title="List View">
              <ListOutlinedIcon />
            </CustomTooltip>
          </CustomToggle>
          <CustomToggle
            data-testid={`viewTab.${TIMESHEET_VIEWS.TABLE}`}
            value={TIMESHEET_VIEWS.TABLE}
          >
            <CustomTooltip title="Table View">
              <TableChartOutlinedIcon />
            </CustomTooltip>
          </CustomToggle>
        </ToggleButtonGroup>
      </ViewSwitcherContainer>
    )
  }

  renderCurrentDate = selectedDate => {
    return (
      <>
        <span className="week-day">{timeUtils.getShortDayOfWeek(selectedDate.getDay())} </span>
        <span className="month">{selectedDate.getMonth() + 1}/</span>
        <span className="days">{selectedDate.getDate()}/</span>
        <span className="year">{selectedDate.getFullYear()}</span>
      </>
    )
  }

  renderDailySelectedDate(selectedDate) {
    return (
      <DateRange ref={this.datePickerRef} onClick={this.props.handleDateRangeClick}>
        {this.renderCurrentDate(selectedDate)}
      </DateRange>
    )
  }

  renderWeeklySelectedDate(selectedDate) {
    const startDate = timeUtils.getFirstDayOfWeek(selectedDate)
    const endDate = timeUtils.addDays(startDate, 6)
    return (
      <DateRange ref={this.datePickerRef} onClick={this.props.handleDateRangeClick}>
        {this.renderCurrentDate(startDate)} - {this.renderCurrentDate(endDate)}
      </DateRange>
    )
  }

  renderDayPickerCalendar() {
    return (
      <Container
        style={{
          border: '1px solid #979797',
          width: 'fit-content',
          boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
          marginBottom: '10px',
        }}
      >
        <DayPickerCalendar
          changeDate={this.props.changeDate}
          selectedDate={this.props.selectedDate}
          isWeeklyView={!this.props.isDayViewPeriodSelected}
          timesheets={this.props.timesheets}
          userSettings={this.props.userSettings}
          userInfo={this.props.userInfo}
          currentMonth={this.props.currentMonth}
          updateCurrentMonth={this.props.updateCurrentMonth}
          navigateToPreviousMissingTimesheet={this.props.navigateToPreviousMissingTimesheet}
          navigateToNextMissingTimesheet={this.props.navigateToNextMissingTimesheet}
          navigateToEarlistMissingTimesheet={this.props.navigateToEarlistMissingTimesheet}
          selectedDelegateId={this.props.selectedDelegateId}
          delegateAccessList={this.props.delegateAccessList}
        />
      </Container>
    )
  }

  renderDatePicker() {
    const { shouldHideLeftArrow, shouldHideRightArrow } = shouldHideArrows(
      this.props.isDayViewPeriodSelected,
      this.props.selectedDate,
    )
    return (
      <DatePickerContainer>
        <DatePickerWrapper data-testid="dateSelector" style={{ padding: '0 20px' }}>
          <ArrowLeft
            hide={shouldHideLeftArrow}
            onMouseDown={this.handleNavigateLeftClick}
            data-testid="dateSelector.previousDay"
            style={{ color: '#050519' }}
          >
            <ChevronLeftIcon style={{ fontSize: 24 }} />
          </ArrowLeft>
          {this.props.isDayViewPeriodSelected &&
            this.renderDailySelectedDate(this.props.selectedDate)}
          {!this.props.isDayViewPeriodSelected &&
            this.renderWeeklySelectedDate(this.props.selectedDate)}
          <ArrowRight
            hide={shouldHideRightArrow}
            onMouseDown={this.handleNavigateRightClick}
            data-testid="dateSelector.nextDay"
            style={{ color: '#050519' }}
          >
            <ChevronRightIcon style={{ fontSize: 24 }} />
          </ArrowRight>
        </DatePickerWrapper>
      </DatePickerContainer>
    )
  }

  render() {
    return (
      <Container style={{ display: 'flex', flexDirection: 'column', width: 'fit-content' }}>
        {this.renderDayPickerCalendar()}
        <Container style={{ display: 'flex', flexDirection: 'row' }}>
          {this.renderPeriodSwitcher()}
          {this.renderViewSwitcher()}
        </Container>
      </Container>
    )
  }
}

CalendarDateSelector.propTypes = {
  onNavigate: PropTypes.func.isRequired,
  changeDate: PropTypes.func.isRequired,
  handleCloseDayPicker: PropTypes.func.isRequired,
  handleDateRangeClick: PropTypes.func.isRequired,
  isDatePickerOpen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  selectedDate: PropTypes.object.isRequired,
  isDayViewPeriodSelected: PropTypes.bool.isRequired,
  setViewPeriodDay: PropTypes.func.isRequired,
  setViewPeriodWeek: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  timesheets: PropTypes.array.isRequired,
  userInfo: PropTypes.object.isRequired,
  currentMonth: PropTypes.instanceOf(Date).isRequired,
  updateCurrentMonth: PropTypes.func.isRequired,
  navigateToPreviousMissingTimesheet: PropTypes.func.isRequired,
  navigateToNextMissingTimesheet: PropTypes.func.isRequired,
  navigateToEarlistMissingTimesheet: PropTypes.func.isRequired,
  handleTogglePageView: PropTypes.func.isRequired,
  userSettings: userSettingsPropTypes,
  selectedDelegateId: PropTypes.string,
  delegateAccessList: PropTypes.array,
}

export default withStyles(styles)(CalendarDateSelector)
