import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Divider from '@material-ui/core/Divider'
import React, { useEffect, useMemo, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import PropTypes from 'prop-types'
import {
  ProjectSectionContainer,
  ProjectsTableContainer,
  StyledProjectsContainer,
  StyledProjectsContent,
  StyledTableHead,
  SubmissionStatusCell,
} from './ProjectsView.styles'
import { fetchAssignmentsByProjectIdsInDateRange } from '../../entities/assignment/service'
import { getLastWeekAndCurrentRanges } from './Approvals.utils'
import { ApprovalsBtn } from '../Calendar/Header/styles'

const WeekDropdown = ({ onChange }) => {
  const [week, setWeek] = useState('current')

  const handleChange = event => {
    if (onChange) onChange(event.target.value)
    setWeek(event.target.value)
  }

  return (
    <FormControl>
      <InputLabel id="week-select-label">Select Week</InputLabel>
      <Select labelId="week-select-label" id="week-select" value={week} onChange={handleChange}>
        <MenuItem value="previous">Previous Week</MenuItem>
        <MenuItem value="current">Current Week</MenuItem>
      </Select>
    </FormControl>
  )
}

WeekDropdown.propTypes = {
  onChange: PropTypes.func,
}
const useOverviewData = ({ projects, projectsAssignments, week, timesheets }) => {
  const [overviewData, setOverviewData] = useState([])

  useEffect(() => {
    if (!projects.length || !projectsAssignments.length) return

    const overViewRows = projects.map(project => {
      const projectAssignments = projectsAssignments.filter(
        proj => proj.projectId === project._id && proj.type === 0,
      )

      const assigmentPeopleTimesheets = timesheets.filter(timesheet => {
        const startDate = new Date(timesheet.startDate)
        const startedAfterWeek = startDate >= week.start
        const startedBeforeEnd = startDate <= week.end
        // if (startDate.getMonth() === 11 && [1, 2].includes(startDate.getDay())) {
        //   if (startedAfterWeek) {
        //     console.log('---------------')
        //
        //     console.log({
        //       startDate,
        //       start: week.start,
        //       end: week.end,
        //       projectAssignments,
        //       startedAfterWeek,
        //       startedBeforeEnd,
        //       timesheet: timesheet.personId,
        //       isOwner: projectAssignments.some(
        //         assignment => assignment.personId === timesheet.personId,
        //       ),
        //     })
        //
        //     console.log('---------------')
        //   } else {
        //     console.log('-----------------------')
        //     console.log('FOLLOWING NOT MATCH', {
        //       timesheet,
        //       date: timesheet.startDate,
        //       expectedStart: week.start,
        //     })
        //
        //     console.log('-----------------------')
        //   }
        // }

        return (
          projectAssignments.some(assignment => assignment.personId === timesheet.personId) &&
          startedAfterWeek &&
          startedBeforeEnd
        )
      })
      let elapsedDays = new Date().getDay() || 7

      if (week.end < new Date().getTime()) {
        elapsedDays = 7
      }

      const expectedTimesheets = projectAssignments.length * elapsedDays

      const submissionStatus = Math.floor(
        (assigmentPeopleTimesheets.length / expectedTimesheets) * 100,
      )

      return {
        projectName: project.name,
        totalStaff: projectAssignments.length,
        submissionStatus: `${submissionStatus}%`,
        delinquencies: expectedTimesheets - assigmentPeopleTimesheets.length,
      }
    })

    setOverviewData(overViewRows)
  }, [projects, projectsAssignments, week, timesheets])

  return overviewData
}

const sortByDate = (data, key) => {
  return data.sort((a, b) => new Date(b[key]) - new Date(a[key]))
}

const useDelinquenciesData = ({ projects, projectsAssignments, week, timesheets }) => {
  const [delinquencies, setDelinquencies] = useState([])
  useEffect(() => {
    if (!projects.length || !projectsAssignments.length) return

    const newData = projectsAssignments.map(assignment => {
      const assignmentTimesheets = timesheets.filter(timesheet => {
        const startDate = new Date(timesheet.startDate)

        const startedAfterWeek = startDate >= week.start
        const startedBeforeEnd = startDate <= week.end
        return timesheet.personId === assignment.personId && startedAfterWeek && startedBeforeEnd
      })
      const lastSubmission = sortByDate(assignmentTimesheets, 'endDate')[0]

      const formattedDate = (lastSubmission?.startDate || '').split('T')[0]

      return {
        personId: assignment.personId,
        projectName: projects.find(proj => proj._id === assignment.projectId)?.name,
        missingDays: `${7 - assignmentTimesheets.length}/7`,
        lastSubmitted: formattedDate,
      }
    })

    setDelinquencies(newData)
  }, [projectsAssignments, projects, week, timesheets])

  return delinquencies
}

const ProjectsView = ({ projects = [], client, people, timesheets }) => {
  const [projectsAssignments, setProjectsAssignments] = useState([])
  const { lastWeek, thisWeek } = useMemo(() => getLastWeekAndCurrentRanges(), [])

  const [week, setWeek] = useState('current')

  const weekView = week === 'current' ? thisWeek : lastWeek

  const delinquenciesData = useDelinquenciesData({
    projects,
    projectsAssignments,
    week: weekView,
    timesheets,
  })

  const overviewData = useOverviewData({
    projects,
    projectsAssignments,
    week: weekView,
    timesheets,
  })

  useEffect(() => {
    if (!projects.length) return
    const projectIds = projects.map(project => project._id).join(',')
    const fetch = async () => {
      const projectAssignments = await fetchAssignmentsByProjectIdsInDateRange({
        projectIds,
        client,
        startISO: lastWeek.start,
        endISO: thisWeek.end,
      })
      setProjectsAssignments(projectAssignments)
    }
    fetch()
  }, [projects, lastWeek, thisWeek])

  return (
    <StyledProjectsContainer>
      <div>
        <WeekDropdown onChange={val => setWeek(val)} />
      </div>

      <StyledProjectsContent>
        <ProjectSectionContainer>
          <h3 unselectable>Project overview</h3>
          <TableContainer component={ProjectsTableContainer}>
            <Table>
              <StyledTableHead>
                <TableRow>
                  <TableCell>Project Name</TableCell>
                  <TableCell>Total Staff</TableCell>
                  <TableCell>Timesheet Submission Status</TableCell>
                  <TableCell>Total Delinquencies</TableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {overviewData.map(row => (
                  <TableRow key={row.projectName}>
                    <TableCell>{row.projectName}</TableCell>
                    <TableCell>{row.totalStaff}</TableCell>
                    <SubmissionStatusCell submissionPercentage={row.submissionStatus}>
                      {row.submissionStatus}
                    </SubmissionStatusCell>
                    <TableCell>{row.delinquencies}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ProjectSectionContainer>

        <Divider />
        <ProjectSectionContainer>
          <h3 unselectable>Delinquencies</h3>

          <TableContainer component={ProjectsTableContainer}>
            <Table>
              <StyledTableHead>
                <TableRow>
                  <TableCell>Person Name</TableCell>
                  <TableCell>Project Name</TableCell>
                  <TableCell>Missing Days</TableCell>
                  <TableCell>Last Submitted Date</TableCell>
                  <TableCell>Nudge</TableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {delinquenciesData.map(row => {
                  const personName = people[row.personId]?.fullName || ''
                  const personEmail = people[row.personId]?.email || ''

                  const weekStartString = weekView.start.toISOString().split('T')[0]
                  const weekEndString = weekView.end.toISOString().split('T')[0]
                  const emailBody = `Please submit your timesheets for the week ${weekStartString} - ${weekEndString}`
                  const emailSubject = `${row.projectName} Assignments`
                  const emailHref = `https://outlook.office.com/mail/deeplink/compose?to=${personEmail}&subject=${emailSubject}&body=${emailBody}`

                  return (
                    <TableRow key={`${row.personName}-${row.projectName}`}>
                      <TableCell>{personName}</TableCell>
                      <TableCell>{row.projectName}</TableCell>
                      <TableCell>{row.missingDays}</TableCell>
                      <TableCell>{row.lastSubmitted}</TableCell>
                      <TableCell>
                        <ApprovalsBtn target="_blank" href={emailHref}>
                          Email
                        </ApprovalsBtn>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </ProjectSectionContainer>
      </StyledProjectsContent>
    </StyledProjectsContainer>
  )
}

ProjectsView.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object),
  client: PropTypes.object,
  timesheets: PropTypes.arrayOf(PropTypes.object),
  people: PropTypes.arrayOf(PropTypes.object),
}

export default ProjectsView
