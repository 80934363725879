import { createTheme } from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey'
import blue from '@material-ui/core/colors/blue'

export default createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: `"Arial Regular", "Arial Medium", sans-serif`,
  },
  palette: {
    primary: blue,
    disabled: {
      main: grey[300],
      dark: '#A6A6A6',
    },
  },
})

export const ksTheme = {
  colors: {
    paper: '#F4F4F4',
    black: '#050519',
    gray: {
      100: '#D0D0D0',
      300: '#B8B8B8',
      500: '#A1A1A1',
      700: '#676767',
      900: '#454545',
      regular: '#898989',
    },
    nightshade: {
      light: '#5856FF',
      medium: '#4241BF',
      dark: '#2C2B80',
    },
    lilac: {
      dark: '#A2A2FF',
      medium: '#B5B5FF',
      light: '#C7C7FF',
    },
    kellyGreen: {
      dark: '#00A162',
      medium: '#33B481',
      light: '#66C7A1',
    },
    darkGreen: {
      light: '#00613B',
      medium: '#00492C',
      dark: '#00331E',
    },
    accentYellow: '#F8FF00',
  },
  fontFamily: {
    sans: ['FK Grotesk Semi-mono', 'sans-serif'],
  },
}
