import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Close from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import Mousetrap from 'mousetrap'
import FocusTrap from 'focus-trap-react'
import _ from 'lodash'
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import Collapse from '@material-ui/core/Collapse'

import * as projectUtils from '../../../utils/projectUtils'
import eventUtils from '../../../utils/eventUtils'
import { getCheckTooltipText } from './ProjectSelector/utils'
import { logErrorMessage } from '../../../utils/logger'
import { isEntryValid } from '../../../models/entry'
import DurationPicker from './DurationPicker'
import ProjectSelector from './ProjectSelector'
import EntryBoxButtons from './EntryBoxButtons'
import {
  timeEntryBoxStyles,
  Container,
  CloseButton,
  Column,
  ActionButtonContainer,
  StyledCheckbox,
  WarningAlert,
} from '../ListView/EntryBoxEditStyle'
import { timesheet, billingOptions } from '../../../constants/billingOptions'
import { onKeyPress } from './shortcuts'

import DebouncedTextInput from './DebouncedTextInput'

import { isAnOperation, getEntry } from '../../../models/entryOperation'

const getActualEntry = entryOrOperation => {
  const isOperation = isAnOperation(entryOrOperation)
  return isOperation ? getEntry(entryOrOperation) : entryOrOperation
}

const warningMessage = {
  title: `If you need to book time off, please do so in ADP.  Once booked in ADP, it will appear in Glimpse.  In case it does not appear in Glimpse, please notify the Glimpse team but you are able to submit your timesheet even if booked time fails to appear in Glimpse.`,
  content: `If you need to book time off, please do so in ADP.`,
}

class NewEntryBox extends PureComponent {
  commentTextFieldRef = React.createRef()

  state = {
    projectError: false,
    timeError: false,
    isDurationPickerVisible: false,
    isButtonBlocked: false,
    isAlertVisible: false,
  }

  componentDidMount = () => {
    this.handleKeyPress()

    const isAlertVisible = sessionStorage.getItem('adpWarning') || true
    this.setState({ isAlertVisible: JSON.parse(isAlertVisible) })

    const isCreating = getActualEntry(this.props.entry)._id === 'placeholder'
    if (!isCreating) return

    const doesNotHaveProject = !_.get(this.props.entry, 'projectId')
    if (doesNotHaveProject) return

    this.commentTextFieldRef.current.focus()
  }

  componentWillUnmount = () => {
    Mousetrap.reset()
  }

  handleKeyPress = () => {
    return onKeyPress({
      dismissEntryBox: this.props.clearCurrentEditableEntry,
      submit: this.submit,
      setDurationPicker: () =>
        this.setState(({ isDurationPickerVisible }) => ({
          isDurationPickerVisible: !isDurationPickerVisible,
        })),
    })
  }

  onSelectSuggestion = project => {
    if (_.isEmpty(project)) this.setState({ projectError: false })
    const fieldsToInherit = projectUtils.getFieldsToInheritToEntry(project)
    this.props.handleUpdateEntry({
      ...fieldsToInherit,
    })
  }

  isSubmitBlocked = () => {
    if (this.props.isFetchingTimesheetData || this.state.isButtonBlocked) return true

    const entry = getActualEntry(this.props.entry)

    const { start, end } = entry
    const minutesDiff = moment(end).diff(start, 'minutes')
    const hasMinTimeSpan = minutesDiff >= 6

    const shouldSubmitBeBlocked = !isEntryValid(entry) || !hasMinTimeSpan

    return shouldSubmitBeBlocked
  }

  handleBillableToggleChange = () => {
    const project = eventUtils.findProjectObject(this.props.entry, this.props.projects)

    if (project === null || project.billable !== billingOptions.BOTH) return
    const newBillable =
      this.props.entry.billable === timesheet.BILLABLE ? timesheet.NONBILLABLE : timesheet.BILLABLE
    this.props.handleUpdateEntry({ billable: newBillable })
  }

  handleCommentChange = event => {
    this.props.handleUpdateEntry({ originalComment: event.target.value })
  }

  submit = () => {
    if (this.isSubmitBlocked()) return
    this.setState({ isButtonBlocked: true })

    return new Promise(resolve => {
      // time out is to allow update comment when user types too fast
      setTimeout(async () => {
        try {
          const updatedEntry = getActualEntry(this.props.entry)
          if (eventUtils.isPlaceholderEntry(updatedEntry)) await this.props.onEntrySubmit()
          else await this.props.onEntryUpdate()
          resolve()
        } catch (e) {
          logErrorMessage(e)
        } finally {
          this.setState({ isButtonBlocked: false })

          const projectSelector = _.get(this.props.projectSelectorRef, 'current', null)
          if (projectSelector) {
            projectSelector.focus()
          }
        }
      }, 500)
    })
  }

  focusCommentTextField = () => {
    const commentTextField = _.get(this.commentTextFieldRef, 'current', null)

    if (commentTextField) {
      commentTextField.focus()
    }
  }

  renderProjectSelector = () => {
    const entry = getActualEntry(this.props.entry)
    return (
      <ProjectSelector
        entry={entry}
        projects={this.props.projects}
        handleFavoriteProjectSelection={this.props.handleFavoriteProjectSelection}
        onSelectSuggestion={this.onSelectSuggestion}
        classes={this.props.classes}
        projectError={this.state.projectError}
        userSettings={this.props.userSettings}
        assignments={this.props.assignments}
        mostRecentlyUsedProjectIds={this.props.mostRecentlyUsedProjectIds}
        handleSetDefaultProject={this.props.handleSetDefaultProject}
        handleClearDefaultProject={this.props.handleClearDefaultProject}
        clearCurrentEditableEntryProject={this.props.clearCurrentEditableEntryProject}
        commentTextFieldRef={this.props.projectSelectorRef}
        focusCommentTextField={() => this.focusCommentTextField()}
        person={this.props.person}
      />
    )
  }

  setTimeError = validation => this.setState({ timeError: validation })

  showDurationPicker = () => this.setState({ isDurationPickerVisible: true })

  hideDurationPicker = () => this.setState({ isDurationPickerVisible: false })

  renderDurationPicker = () => {
    const entry = getActualEntry(this.props.entry)
    return (
      <DurationPicker
        showDurationPicker={this.showDurationPicker}
        hideDurationPicker={this.hideDurationPicker}
        isDurationPickerVisible={this.state.isDurationPickerVisible}
        timeError={this.state.timeError}
        setTimeError={this.setTimeError}
        entry={entry}
        handleUpdateEntry={this.props.handleUpdateEntry}
        classes={this.props.classes}
      />
    )
  }

  renderButtons = () => {
    return (
      <EntryBoxButtons
        entry={this.props.entry}
        durationPickerData={this.state.durationPickerData}
        onEntryDelete={this.props.onEntryDelete}
        classes={this.props.classes}
        onSubmit={this.submit}
        isSubmitBlocked={this.isSubmitBlocked()}
      />
    )
  }

  renderCommentInput = () => {
    const entry = getActualEntry(this.props.entry)
    return (
      <DebouncedTextInput
        commentTextFieldRef={this.commentTextFieldRef}
        onChange={this.props.handleUpdateEntry}
        entry={entry}
      />
    )
  }

  renderCheckTooltip = (projectObject, isBillableChecked) => {
    const checkTooltipText = getCheckTooltipText(projectObject, isBillableChecked)

    return (
      <span>
        {projectObject.billable === billingOptions.BOTH && (
          <>
            Status: <strong>{isBillableChecked ? 'Billable' : 'No Billable'}</strong>.
          </>
        )}
        {checkTooltipText}
      </span>
    )
  }

  renderBillableCheckbox = () => {
    const entry = getActualEntry(this.props.entry)
    const projectObject = eventUtils.findProjectObject(
      entry,
      this.props.projects,
      this.props.userSettings.defaultProjectId,
    )
    const isBillableChecked = this.props.entry.billable === timesheet.BILLABLE
    const checkTooltip = this.renderCheckTooltip(projectObject, isBillableChecked)
    const isProjectEmptyOrNonBillable =
      _.isEmpty(projectObject) || projectObject.billable !== billingOptions.BOTH

    return (
      <div>
        <Tooltip
          title={checkTooltip}
          classes={{ tooltip: this.props.classes.tooltipText, popper: this.props.classes.popper }}
        >
          <div>
            <StyledCheckbox
              checked={isBillableChecked}
              onChange={this.handleBillableToggleChange}
              disabled={isProjectEmptyOrNonBillable}
              disableFocusRipple
              icon={
                <AttachMoneyIcon
                  fontSize="large"
                  color={isProjectEmptyOrNonBillable ? 'disabled' : 'inherit'}
                />
              }
              checkedIcon={<AttachMoneyIcon fontSize="large" color="inherit" />}
            />
          </div>
        </Tooltip>
      </div>
    )
  }

  focusProjectSelector = () => {
    const projectSelector = _.get(this.props.projectSelectorRef, 'current', null)
    if (projectSelector) {
      projectSelector.focus()
    }
  }

  handleAlert = () => {
    this.setState({ isAlertVisible: false })
    sessionStorage.setItem('adpWarning', false)
  }

  render() {
    return (
      <FocusTrap focusTrapOptions={{ clickOutsideDeactivates: () => true }}>
        <Container ref={this.entryBoxContainerRef} style={{height: 'fit-content'}}>
          <Column width="70%">
            {this.renderProjectSelector()}
            {this.renderCommentInput()}
          </Column>
          <Column width="30%">
            {this.renderDurationPicker()}
            <ActionButtonContainer>
              {this.renderBillableCheckbox()}
              {this.renderButtons()}
            </ActionButtonContainer>
          </Column>
          <Column width="100%">
            <Collapse in={this.state.isAlertVisible}>
              <Tooltip
                title={warningMessage.title}
                classes={{
                  tooltip: this.props.classes.tooltipText,
                  popper: this.props.classes.popper,
                }}
              >
                <WarningAlert variant="outlined" severity="warning" onClose={this.handleAlert}>
                  {warningMessage.content}
                </WarningAlert>
              </Tooltip>
            </Collapse>
          </Column>
          {(!this.props.entry.hideOnWeekPeriod ||
            !_.isEqual(this.props.entry, this.props.initialCurrentEditableEntry)) && (
            <CloseButton
              data-testid="entryBox.dissmissButton"
              onClick={() =>
                this.props.clearCurrentEditableEntry(() => this.focusProjectSelector())
              }
            >
              <Close color="primary" />
            </CloseButton>
          )}
        </Container>
      </FocusTrap>
    )
  }
}

NewEntryBox.propTypes = {
  classes: PropTypes.object.isRequired,
  entry: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired,
  mostRecentlyUsedProjectIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleUpdateEntry: PropTypes.func.isRequired,
  onEntrySubmit: PropTypes.func.isRequired,
  onEntryUpdate: PropTypes.func.isRequired,
  onEntryDelete: PropTypes.func.isRequired,
  clearCurrentEditableEntry: PropTypes.func.isRequired,
  handleFavoriteProjectSelection: PropTypes.func.isRequired,
  handleSetDefaultProject: PropTypes.func.isRequired,
  handleClearDefaultProject: PropTypes.func.isRequired,
  clearCurrentEditableEntryProject: PropTypes.func.isRequired,
  isFetchingTimesheetData: PropTypes.bool.isRequired,
  userSettings: PropTypes.shape({
    favoriteProjectIds: PropTypes.array,
    defaultProjectId: PropTypes.string,
  }).isRequired,
  assignments: PropTypes.array.isRequired,
  projectSelectorRef: PropTypes.object,
  initialCurrentEditableEntry: PropTypes.object,
  person: PropTypes.object.isRequired,
}

export default withStyles(timeEntryBoxStyles)(NewEntryBox)
