import React, { useState } from 'react'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import PropTypes from 'prop-types'
import UserProfile from '../Calendar/Header/UserProfile'
import { ApprovalsBtn, Container, MenuContainer } from '../Calendar/Header/styles'

import {
  ApprovalsContainer,
  StyledPageContainer,
  StyledToggleButton,
  StyledToggleContainer,
} from './Approvals.styles'
import ApprovalTable from './ApprovalsView'
import useApprovalsData from './useApprovalsData'
import ProjectsView from './ProjecstView'

const views = {
  approvals: 'approvals',
  projects: 'projects',
}

const Approvals = ({ person, client }) => {
  const [view, setView] = useState(views.projects)

  const { projects, timeEntries, approvalsTableRows, timesheets, people } = useApprovalsData({
    client,
    person,
  })

  return (
    <StyledPageContainer>
      <Container>
        <UserProfile person={person} />
        <MenuContainer>
          <ApprovalsBtn href="#">Timesheets</ApprovalsBtn>
        </MenuContainer>
      </Container>
      <StyledToggleContainer>
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={(_, selectedView) => {
            if (!selectedView) return
            setView(selectedView)
          }}
        >
          <StyledToggleButton value={views.approvals}>My Approvals</StyledToggleButton>
          <StyledToggleButton value={views.projects}>Project Dashboard</StyledToggleButton>
        </ToggleButtonGroup>
      </StyledToggleContainer>
      <ApprovalsContainer>
        {view === views.approvals && (
          <ApprovalTable
            projects={projects}
            timeEntries={timeEntries.pending}
            rows={approvalsTableRows}
          />
        )}
        {view === views.projects && (
          <ProjectsView
            projects={projects}
            client={client}
            people={people}
            timesheets={timesheets}
          />
        )}
      </ApprovalsContainer>
    </StyledPageContainer>
  )
}

Approvals.propTypes = {
  client: PropTypes.object.isRequired,

  person: PropTypes.object.isRequired,
}

export default Approvals
