import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import withSizes from 'react-sizes'

import { Grid } from '@material-ui/core'
import EntryBoxPreview from './EntryBoxPreview'
import ContextMenu from '../../../components/contextMenu'
import NewEntryBox from '../NewEntryBox'
import { EntryListContainer } from './EntryBoxEditStyle'
import EmptyEntryBoxPreview from './EmptyEntryBoxPreview'
import { isEntryStartDateEqualToDate } from '../utils'
import eventUtils from '../../../utils/eventUtils'
import timeUtils from '../../../utils/timeUtils'
import HorizontalSplitter from '../TimelineView/HorizontalSplitter'

import { addKeyDownListener, removeKeyDownListener } from '../../../utils/appUtils'
import { listViewEntryListKeyDown, entryCreationKeyDown } from './shortcuts'
import { breakpoints } from '../../../constants/breakpoints'

import { List, ListContainer, ListViewContainer } from './style'

const START_WORK_HOUR = 8
const END_WORK_HOUR = 23
const MINIMAL_EVENT_DURATION = 30

class ListView extends React.PureComponent {
  state = {
    selectedDate: this.props.selectedDate,
    dayStartTime: timeUtils.setHoursWithoutMinSec(this.props.selectedDate, START_WORK_HOUR),
    dayEndTime: timeUtils.setHoursWithoutMinSec(this.props.selectedDate, END_WORK_HOUR),
  }

  componentDidMount() {
    addKeyDownListener(this.handleEntryCreation)
    const el = document.getElementById('tabindex_1')
    if (el) {
      el.focus()
    }
  }

  componentWillUnmount() {
    removeKeyDownListener(this.handleEntryCreation)
  }

  static getDerivedStateFromProps(props, state) {
    if (props.selectedDate !== state.selectedDate) {
      return {
        selectedDate: props.selectedDate,
        dayStartTime: timeUtils.setHoursWithoutMinSec(props.selectedDate, START_WORK_HOUR),
        dayEndTime: timeUtils.setHoursWithoutMinSec(props.selectedDate, END_WORK_HOUR),
      }
    }

    return null
  }

  getNextTabIndexNode = currentNode => {
    const nextNode = currentNode.nextSibling
    if (_.isNull(nextNode)) return null

    return nextNode
  }

  getPrevTabIndexNode = currentNode => {
    const prevNode = currentNode.previousSibling
    if (_.isNull(prevNode)) return null

    return prevNode
  }

  handleEntryCreation = () => {
    return entryCreationKeyDown({
      currentEditableEntry: this.props.currentEditableEntry,
      onEntrySubmit: this.props.onEntrySubmit,
      addCalendarPlaceholderEvent: this.props.addCalendarPlaceholderEvent,
      selectedDate: this.props.selectedDate,
      selectedDayEntries: this.props.selectedDayEntries,
      onEntryUpdate: this.props.onEntryUpdate,
      isTimesheetLocked: this.props.isTimesheetLocked,
    })
  }

  handleEntryKeyDown = (event, entry) => {
    return listViewEntryListKeyDown({
      event,
      getNextTabIndexNode: this.getNextTabIndexNode,
      getPrevTabIndexNode: this.getPrevTabIndexNode,
      isSubmitedTimesheet: this.props.isSubmitedTimesheet,
      onSelectEntryToEdit: this.props.onSelectEntryToEdit,
      createPlaceholderEntry: this.props.createPlaceholderEntry,
      onEntryDelete: this.props.onEntryDelete,
      entry,
    })
  }

  isLocked = event =>
    this.props.isTimesheetLocked || (eventUtils.isTimeOffEvent(event) && !this.props.isConnected)

  renderEntryBoxPreview = (calendarEvent, index, isEntryBeingEdited = false) => {
    const isLocked = this.isLocked(calendarEvent) || isEntryBeingEdited
    return (
      <ContextMenu
        actions={this.props.getContextMenuActions()}
        timeEntry={calendarEvent}
        isLocked={isLocked}
        key={`entry_preview_${index}`}
      >
        <EntryBoxPreview
          entry={calendarEvent}
          handleKeyDown={this.handleEntryKeyDown}
          tabIndex={index + 1}
          projects={this.props.projects}
          onSelectEntryToEdit={this.props.onSelectEntryToEdit}
          isLocked={isLocked}
          isEntryBeingEdited={isEntryBeingEdited}
        />
      </ContextMenu>
    )
  }

  renderEntryBoxEdit = () => {
    return (
      <EntryListContainer>
        <NewEntryBox
          key="entry_edit_current_editable_entry"
          entry={this.props.currentEditableEntry}
          initialCurrentEditableEntry={this.props.initialCurrentEditableEntry}
          projects={this.props.projects}
          handleUpdateEntry={this.props.handleUpdateCurrentEditableEntry}
          mostRecentlyUsedProjectIds={this.props.mostRecentlyUsedProjectIds}
          userSettings={this.props.userSettings}
          assignments={this.props.assignments}
          handleFavoriteProjectSelection={this.props.handleFavoriteProjectSelection}
          handleSetDefaultProject={this.props.handleSetDefaultProject}
          handleClearDefaultProject={this.props.handleClearDefaultProject}
          onEntrySubmit={this.props.onEntrySubmit}
          onEntryUpdate={this.props.onEntryUpdate}
          onEntryDelete={this.props.onEntryDelete}
          clearCurrentEditableEntry={this.props.clearCurrentEditableEntry}
          clearCurrentEditableEntryProject={this.props.clearCurrentEditableEntryProject}
          isTimeLineView={false}
          isFetchingTimesheetData={this.props.isFetchingTimesheetData}
          projectSelectorRef={this.props.projectSelectorRef}
          person={this.props.person}
        />
      </EntryListContainer>
    )
  }

  renderEmptyEntryBoxPreview = (start, end, index, shiftInMinutes = 60) => {
    if (!this.props.showEmptyEntryBox) return null
    return (
      <EmptyEntryBoxPreview
        key={`entry_empty_${index}`}
        onCreateTimeEntry={this.props.createPlaceholderEntry}
        start={start}
        end={end}
        shift={shiftInMinutes}
        tabIndex={index + 1}
      />
    )
  }

  isTimeDiffMoreThenMinEventDuration = (a, b) => {
    return timeUtils.getDurationInMinutes(a, b) > MINIMAL_EVENT_DURATION
  }

  isAfterStartWorkingTime = date => date.getHours() > START_WORK_HOUR

  isBeforeEndWorkingTime = date => date.getHours() < END_WORK_HOUR

  renderEntryBoxesWithEmptyCard = (acc, entry, index, entries) => {
    const entryStart = entry.start || entry.data.start
    const entryEnd = entry.end || entry.data.end
    const nextEntry = entries[index + 1]
    const isLastEntry = index === entries.length - 1
    const nextEntryStart = !isLastEntry && (nextEntry.start || nextEntry.data.start)

    const isFirstEntry = index === 0

    if (isFirstEntry && this.isAfterStartWorkingTime(entryStart)) {
      acc.isEmptyCardAdded = true
      acc.rendered.push(this.renderEmptyEntryBoxPreview(this.state.dayStartTime, entryStart, index))
    }

    acc.rendered.push(this.renderTimeEntryBox(entry, index))

    if (
      !isLastEntry &&
      this.isTimeDiffMoreThenMinEventDuration(entryEnd, nextEntryStart) &&
      this.isAfterStartWorkingTime(nextEntryStart) &&
      this.isBeforeEndWorkingTime(entryEnd)
    ) {
      acc.isEmptyCardAdded = true
      acc.rendered.push(this.renderEmptyEntryBoxPreview(entryEnd, nextEntryStart, index + 1))
    }

    if (isLastEntry && this.isBeforeEndWorkingTime(entryEnd)) {
      acc.isEmptyCardAdded = true
      acc.rendered.push(this.renderEmptyEntryBoxPreview(entryEnd, this.state.dayEndTime, index + 1))
    }

    return acc
  }

  appendFirstAndLastEmptyCard = (renderedEntries, firstEntry, lastEntry) => {
    const start = firstEntry.start || firstEntry.data.start
    const end = lastEntry.end || lastEntry.data.end
    return _.concat(
      this.renderEmptyEntryBoxPreview(timeUtils.addMinutes(start, -15), start, 'first', 15),
      renderedEntries,
      this.renderEmptyEntryBoxPreview(end, timeUtils.addMinutes(end, 15), 'last', 15),
    )
  }

  renderEntryBoxes(entries) {
    const { rendered } = _.reduce(entries, this.renderEntryBoxesWithEmptyCard, {
      rendered: [],
      isEmptyCardAdded: false,
    })
    return rendered
  }

  renderTimeEntryBox(entry, index) {
    const isEntryBeingEdited =
      !_.isEmpty(this.props.currentEditableEntry) &&
      _.isEqual(
        this.props.currentEditableEntry._id || this.props.currentEditableEntry.sourceId,
        entry._id || entry.sourceId,
      )
    return this.renderEntryBoxPreview(entry, index, isEntryBeingEdited)
  }

  render() {
    const isEntryBoxActive = !_.isEmpty(this.props.currentEditableEntry)
    const filteredDayEntries = this.props.selectedDayEntries.filter(entry => {
      return isEntryStartDateEqualToDate(entry, this.props.selectedDate)
    })

    const entriesToSort =
      isEntryBoxActive &&
      this.props.currentEditableEntry._id === 'placeholder' &&
      !this.props.isSubmitedTimesheet
        ? [...filteredDayEntries, this.props.currentEditableEntry]
        : filteredDayEntries
    const ascSortedDayEntries = eventUtils.sortEventsByStartTime(entriesToSort)
    const isEmptyEntries = _.isEmpty(ascSortedDayEntries)

    if (this.props.isMobile) {
      return (
        <>
          <HorizontalSplitter>
            <List>
              {isEmptyEntries
                ? this.renderEmptyEntryBoxPreview(this.state.dayStartTime, this.state.dayEndTime, 0)
                : this.renderEntryBoxes(ascSortedDayEntries)}
            </List>
            {this.props.children}
          </HorizontalSplitter>
          {isEntryBoxActive && !this.props.isSubmitedTimesheet && this.renderEntryBoxEdit()}
        </>
      )
    }

    return (
      <Grid container className="list-view" direction="column" style={{ flex: 1 }}>
        <ListViewContainer item xs>
          <ListContainer>
            <List>
              {isEmptyEntries
                ? this.renderEmptyEntryBoxPreview(this.state.dayStartTime, this.state.dayEndTime, 0)
                : this.renderEntryBoxes(ascSortedDayEntries)}
            </List>
          </ListContainer>
          {this.props.children}
        </ListViewContainer>
      </Grid>
    )
  }
}

ListView.propTypes = {
  selectedDayEntries: PropTypes.array.isRequired,
  selectedDate: PropTypes.object.isRequired,
  currentEditableEntry: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired,
  onSelectEntryToEdit: PropTypes.func.isRequired,
  createPlaceholderEntry: PropTypes.func.isRequired,
  showEmptyEntryBox: PropTypes.bool.isRequired,
  isSubmitedTimesheet: PropTypes.bool.isRequired,
  onEntrySubmit: PropTypes.func.isRequired,
  clearCurrentEditableEntry: PropTypes.func.isRequired,
  onEntryDelete: PropTypes.func.isRequired,
  onEntryUpdate: PropTypes.func.isRequired,
  mostRecentlyUsedProjectIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleUpdateCurrentEditableEntry: PropTypes.func.isRequired,
  handleFavoriteProjectSelection: PropTypes.func.isRequired,
  userSettings: PropTypes.shape({
    favoriteProjectIds: PropTypes.array,
    defaultProjectId: PropTypes.string,
  }).isRequired,
  assignments: PropTypes.array.isRequired,
  handleSetDefaultProject: PropTypes.func.isRequired,
  handleClearDefaultProject: PropTypes.func.isRequired,
  clearCurrentEditableEntryProject: PropTypes.func.isRequired,
  getContextMenuActions: PropTypes.func.isRequired,
  addCalendarPlaceholderEvent: PropTypes.func.isRequired,
  isTimesheetLocked: PropTypes.bool.isRequired,
  isFetchingTimesheetData: PropTypes.bool.isRequired,
  isConnected: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
  projectSelectorRef: PropTypes.object,
  initialCurrentEditableEntry: PropTypes.object,
  person: PropTypes.object.isRequired,
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < breakpoints.md,
})

export default withSizes(mapSizesToProps)(ListView)
